export const LEVEL_WIDTH = 60;
export const LEVEL_HEIGHT = 24;
export const BODY_LENGTH = LEVEL_WIDTH * LEVEL_HEIGHT;
export const FOOTER_BYTE_LENGTH = 96;
export const LEVEL_BYTES_LENGTH = BODY_LENGTH + FOOTER_BYTE_LENGTH;
export const TITLE_LENGTH = 23;
export const SIGNATURE_MAX_LENGTH = 511;

export const SPEC_PORT_MAX_COUNT = 10;
export const SPEC_PORT_ITEM_LENGTH = 6;
export const SPEC_PORTS_DB_SIZE = SPEC_PORT_MAX_COUNT * SPEC_PORT_ITEM_LENGTH;
