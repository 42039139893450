// @ts-check
// This file is automatically generated. DO NOT EDIT

export function CreateFile(arg1, arg2) {
  return window["go"]["main"]["App"]["CreateFile"](arg1, arg2);
}

export function GetAppInfo() {
  return window["go"]["main"]["App"]["GetAppInfo"]();
}

export function OpenFile(arg1) {
  return window["go"]["main"]["App"]["OpenFile"](arg1);
}

export function SaveFileAs(arg1, arg2) {
  return window["go"]["main"]["App"]["SaveFileAs"](arg1, arg2);
}

export function SetIsDirty(arg1) {
  return window["go"]["main"]["App"]["SetIsDirty"](arg1);
}
